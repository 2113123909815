import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import _ from 'lodash';
import { metaData } from '../assets/headphone-parts-image_meta-data';

import useOrderStore from '@/context/useOrderStore'
import Nav from '@/components/Nav'
import DynamicHeadphones from '@/components/DynamicHeadphones'
import SimpleHeadphones from '@/components/SimpleHeadphones'

import ProductSettings from '@/components/ProductSettings'
import Engraving from '@/components/Engraving'
import useBeforeUnload from '@/hooks/useBeforeUnload'
import Colour from '../components/Colour';
import ShippingForm from '../components/ShippingForm';
import { BadgeHelp, Lock, Monitor, X } from 'lucide-react';
import { generateUid } from '@/helpers/helpers';
import HeadphoneItem from '../components/HeadphoneItem';
import DynamicCover from '../components/DynamicCover';

const Main = () => {
  const navigate = useNavigate();
  const { orderId, activeOrderItemId, component } = useParams()
  const { setOrder, untouchedOrder, setUntouchedOrder, recalculatePay, setProductData, order, productData, kickstarterOrder, setKickstarterOrder, addonIds, addOrderItem } = useOrderStore()

  const [coverColour, setCoverColour] = useState();
  const [showCover, setShowCover] = useState(false);

  // const timeoutIdRef = useRef(null);
  const [caseTimeout, setCaseTimeout] = useState(null)

  const toggleCover = (boolean, coverColour) => {
    // Clear the existing timeout to prevent weird behavior
    if (caseTimeout) {
      clearTimeout(caseTimeout);
      setCaseTimeout(null);
    }

    if (boolean === true) {
      setShowCover(true);
      setCoverColour(coverColour.name);

      // Set a new timeout to hide the cover after 2400ms
      const _caseTimeout = setTimeout(() => {
        setShowCover(false);
        setCoverColour(null)
        setCaseTimeout(null);
      }, 2400);

      setCaseTimeout(_caseTimeout);
    } else if (boolean === false) {
      const _caseTimeout = setTimeout(() => {
        setShowCover(false);
        setCoverColour(null)
        setCaseTimeout(null);
      }, 50);

      setCaseTimeout(_caseTimeout);
    }
  };


  const [surveyComplete, setSurveyComplete] = useState(false)

  const [updatingOrder, setUpdatingOrder] = useState(false)

  const [shippingModalOpen, setShippingModalOpen] = useState(false)

  const [changesMade, setChangesMade] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orderChangedOnServer, setOrderChangedOnServer] = useState(false)
  const [welcomeModalClosed, setWelcomeModalClosed] = useState(false)

  const [viewName, setViewName] = useState('front_view')

  const [headphonesInteractive, setHeadphonesInteractive] = useState(false)
  const [showColourPicker, setShowColourPicker] = useState(false)
  const [colourPickerPos, setColourPickerPos] = useState({ x: 0, y: 0 })
  const [colourPickerPartName, setColourPickerPartName] = useState(null)

  const [engravingText, setEngravingText] = useState('')

  const [selectedOrderItem, setSelectedOrderItem] = useState(null)
  const [statusHistory, setStatusHistory] = useState([]);

  useBeforeUnload(changesMade);

  useEffect(() => {

    const fetchOrderData = async () => {
      try {
        const response = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/prod/orders/' + orderId);
        if (!response.ok) {
          navigate('/');
          throw new Error('Network response not ok');
        }

        const data = await response.json();

        // Extract and set status_history
        const extractedStatusHistory = data.status_history || [];
        setStatusHistory(extractedStatusHistory); // Ensure this is set correctly

        setWelcomeModalClosed(!_.isEqual(data.updated_order, data.original_order));
        setUntouchedOrder(data.updated_order);
        setOrder(data.updated_order);
        setKickstarterOrder(data.original_order);

        // Determine the latest status based on dateTime
        let latestStatus;
        if (Array.isArray(data.status)) {
          latestStatus = data.status.reduce((latest, current) => {
            return new Date(current.dateTime) > new Date(latest.dateTime) ? current : latest;
          });
          latestStatus = latestStatus.status; // Extract the status string after finding the latest
        } else {
          latestStatus = data.status; // If not an array, use it directly
        }

        // Perform navigation based on the latest status
        if (latestStatus !== "ordered" && latestStatus !== "survey_sent" && latestStatus !== "empty_order") {
          setSurveyComplete(true);
          navigate('/tracking/' + orderId, {
            state: { statusHistory: extractedStatusHistory } // Pass the extracted statusHistory directly
          });
        }

        // if (latestStatus === "delivered") {
        //   setSurveyComplete(true);
        //   navigate('/delivered');
        // } else if (latestStatus === "outfordelivery") {
        //   setSurveyComplete(true);
        //   navigate('/outfordelivery');
        // } else if (latestStatus !== 'ordered' && latestStatus !== 'survey_sent' && latestStatus !== 'empty_order' && latestStatus !== 'configurator_sent') {
        //   setSurveyComplete(true);
        //   navigate('/tracking/' + orderId, {
        //     state: { statusHistory: extractedStatusHistory } // Pass the extracted statusHistory directly
        //   });
        // }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };





    const fetchProductData = async () => {
      try {
        const response = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/prod/products')
        if (!response.ok) {
          throw new Error('Network response not ok')
        }
        const data = await response.json()
        setProductData(data)
      } catch (error) {

      } finally {

      }
    }

    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([fetchOrderData(), fetchProductData()])
      } catch (error) {
        console.error(error)
      } finally {
        recalculatePay()
        setLoading(false)
      }
    }

    fetchData()
  }, [orderId]);

  useEffect(() => {
    if (!order?.items || order.items.length === 0) return; // Ensure order.items exists and is not empty

    const firstItem = order.items[0].item_uid.$oid;

    if (order && (activeOrderItemId === undefined || activeOrderItemId === null)) {
      window.location.href = `/${orderId}/${firstItem}`;
    } else if (!order.items.find(item => item.item_uid.$oid === activeOrderItemId)) {
      navigate('/' + orderId + '/' + firstItem);
    }
  }, [order]);

  useEffect(() => {
    if (!order || !kickstarterOrder) return


    const orderHasBeenChanged = !_.isEqual(order.items, kickstarterOrder.items)

  }, [order, kickstarterOrder])

  useEffect(() => {
    if (!order || !order.items || order.items.length === 0) return; // Ensure order.items exists and is not empty

    const routedOrderItem = order.items.find(item => item.item_uid.$oid === activeOrderItemId);
    const firstItem = order.items[0].item_uid.$oid;

    if (!routedOrderItem) {
      navigate('/' + orderId + '/' + firstItem);
    } else {
      setSelectedOrderItem(routedOrderItem);
    }
  }, [activeOrderItemId, order]);

  const updateOrderOnServer = async (updatedOrder) => {
    setUpdatingOrder(true)
    const updatedOrderObject = {
      updated_order: {
        ...updatedOrder,
        items: updatedOrder.items.map(item => ({
          ...item,
          parts: item.parts || null
        }))
      }
    }
    try {
      const response = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/test/orders/updateOrder/' + orderId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedOrderObject),

      })
      // console.log("updatedOrderObject", JSON.stringify(updatedOrderObject))
      if (!response.ok) {
        setUpdatingOrder(false)
        return false
      }

      const data = await response.json()
      setUntouchedOrder(data.document)
      setOrder(data.document)
      recalculatePay()
      return true
    } catch (error) {
      console.error(error)
      return false
    } finally {
      setUpdatingOrder(false)
    }
  }

  useEffect(() => {
    setChangesMade(!_.isEqual(order, untouchedOrder))
  }, [order, untouchedOrder])

  useEffect(() => {
    if (component === 'engraving') {
      setViewName('top_view')
    } else if (component === 'colour') {
      setViewName('front_view')
    } else if (!component) {
      setViewName('front_view')
    }
  }, [component])

  const renderView = (cover = false) => {
    if (!component) {
      return <ProductSettings className='z-20' orderItem={selectedOrderItem} toggleCover={toggleCover} cover={cover} />
    } else if (component === 'engraving') {
      return <Engraving
        orderItem={selectedOrderItem}
        setEngravingText={setEngravingText}
        className='max-h-28 mt-4 md:mt-0 md:max-h-auto' />
    } else if (component === 'colour') {
      return <Colour
        orderItem={selectedOrderItem}
        showColourPicker={showColourPicker}
        colourPickerPos={colourPickerPos}
        colourPickerPartName={colourPickerPartName}
        className='max-h-28  mt-4 md:mt-0 md:max-h-auto' />
    }
  }

  const toggleColourPicker = (partName, mousePos) => {
    if (partName) {

      setColourPickerPos({ x: mousePos.x, y: mousePos.y })
      setColourPickerPartName(partName)
      setShowColourPicker(true)
    } else {
      setShowColourPicker(false)
    }
  }

  useEffect(() => {

  }, [showColourPicker])

  useEffect(() => {
    if (component === 'colour' && selectedOrderItem?.colours === 'custom_colours') {
      setHeadphonesInteractive(true)
    } else {
      setHeadphonesInteractive(false)
    }
  }, [component, selectedOrderItem?.colours])

  const helpEmailWithOrderNumber = () => {
    const subject = `Help with order: ${orderId}`;
    const mailtoLink = `mailto:help@kibu.family?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  }

  const handleSaveOrderClicked = async () => {
    const success = await updateOrderOnServer(order)
    if (success) {
      // Force a re-render by updating a state variable
      setChangesMade(false)
    } else {
      // Handle error case
      console.error("Failed to save order")
    }
  }

  const openShippingModal = () => {
    setShippingModalOpen(true)
  }

  const createNewHeadphone = (colourWay = null) => {
    const newItem = {
      product_id: {
        $oid: addonIds.headphones
      },
      item_uid: {
        $oid: generateUid()
      },
      colours: colourWay ? colourWay : null,
      addons: [],
      protected: false,
      quantity: 1,
    };

    // If the colourWay is 'custom_colours', add the custom colour addon
    if (colourWay === 'custom_colours') {
      const customColourAddon = {
        item_uid: {
          $oid: generateUid()
        },
        product_id: {
          $oid: "6644c1946c7d7162c757dffb"
        },
        protected: true,
        type: "Custom Colour",
        custom_colours: {
          outer_donut_right: null,
          middle_donut_right: null,
          clip_right: null,
          inner_right: null,
          outer_donut_left: null,
          middle_donut_left: null,
          clip_left: null,
          inner_left: null,
          headband: null
        },
        inner_grill: null
      };
      newItem.addons.push(customColourAddon);
    }
    addOrderItem(newItem);
    recalculatePay();
    navigate('/' + orderId + '/' + newItem.item_uid.$oid);
  };

  const createNewCover = (colourWay = null) => {
    const newItem = {
      product_id: {
        $oid: addonIds.case
      },
      item_uid: {
        $oid: generateUid()
      },
      colour: colourWay ? colourWay : null,
      protected: false,
      quantity: 1,
    };

    addOrderItem(newItem);
    recalculatePay();
    navigate('/' + orderId + '/' + newItem.item_uid.$oid);
  };


  const getPartsDataForColourway = (viewName, colourwayName) => {
    const view = metaData.views.find(view => view[viewName])?.[viewName];
    const colourway = metaData.colours.standard_colourways.find(colourway => colourway.colourway_name === colourwayName);

    if (!view || !colourway) return [];

    return Object.entries(view)
      .filter(([partName, part]) => part.active && partName !== 'cover') // Only include active parts and skip 'cover'
      .map(([partName, part]) => {
        let src;

        // Check if the part is a cushion and exclude color suffix if so
        if (partName === 'cushion_left' || partName === 'cushion_right' || partName === 'cable') {
          src = `/assets/headphones/${viewName}/${partName}.webp`;
        } else {
          const selectedColour = colourway[partName]?.selected_colour || 'pearl'; // Default to 'pearl' if not found
          src = `/assets/headphones/${viewName}/${partName}_${selectedColour}.webp`;
        }

        return {
          part_name: partName,
          index: part.index, // zIndex equivalent
          src: src,
        };
      });
  };

  const partsArcticData = getPartsDataForColourway('front_view', "arctic_blue");
  const partsDesertData = getPartsDataForColourway('front_view', "desert_peach");
  const partsForestData = getPartsDataForColourway('front_view', "forest_green");

  const colours = ["pistachio", "arctic", "tangerine", "olive", "peach", "buttercup", "pearl"];

  const customColourData = [
    {
      "part_name": "headband",
      "index": 0,
      "src": "/assets/headphones/front_view/headband_tangerine.webp"
    },
    {
      "part_name": "cable",
      "index": 1,
      "src": "/assets/headphones/front_view/cable.webp"
    },
    {
      "part_name": "clip_left",
      "index": 2,
      "src": "/assets/headphones/front_view/clip_left_arctic.webp"
    },
    {
      "part_name": "clip_right",
      "index": 3,
      "src": "/assets/headphones/front_view/clip_right_buttercup.webp"
    },
    {
      "part_name": "middle_donut_right",
      "index": 4,
      "src": "/assets/headphones/front_view/middle_donut_right_tangerine.webp"
    },
    {
      "part_name": "middle_donut_left",
      "index": 5,
      "src": "/assets/headphones/front_view/middle_donut_left_pistachio.webp"
    },
    {
      "part_name": "outer_donut_left",
      "index": 6,
      "src": "/assets/headphones/front_view/outer_donut_left_buttercup.webp"
    },
    {
      "part_name": "outer_donut_right",
      "index": 7,
      "src": "/assets/headphones/front_view/outer_donut_right_arctic.webp"
    },
    {
      "part_name": "cushion_left",
      "index": 8,
      "src": "/assets/headphones/front_view/cushion_left.webp"
    },
    {
      "part_name": "cushion_right",
      "index": 9,
      "src": "/assets/headphones/front_view/cushion_right.webp"
    }
  ]

  const [colourData, setColourData] = useState(customColourData);

  const renderComponentBasedOnProductId = () => {
    if (!order?.items || order.items.length === 0) {
      return (
        <div className="view main relative overflow-hidden w-screen bg-background h-[100dvh] max-h-full flex flex-col gap-4 md:gap-0">
          <Nav order={order} createNewHeadphone={createNewHeadphone} createNewCover={createNewCover} saveOrderClicked={handleSaveOrderClicked} />
          <div className="flex-1 flex items-center justify-center flex-col">
            <p className="text-2xl mb-4">No items are currently in your order.</p>
            <div className="flex gap-4">
              <button
                className="bg-orange text-white px-6 py-3 rounded-full hover:bg-orange-600 transition-colors"
                onClick={() => createNewHeadphone('arctic_blue')}
              >
                Add a Headphone
              </button>
              <button
                className="bg-orange text-white px-6 py-3 rounded-full hover:bg-orange-600 transition-colors"
                onClick={() => createNewCover()}
              >
                Add a Cover
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (selectedOrderItem?.product_id.$oid === "621e2d34b51d4e21d1fa1234") {
      return (
        <div className={`view main relative overflow-hidden w-screen bg-background h-[100dvh] max-h-full flex flex-col gap-4 md:gap-0 transition-bg ease-kibu duration-200 ${headphonesInteractive ? 'bg-orange/10' : 'bg-background'}`}>
          <Nav
            finishOrderClicked={openShippingModal}
            order={order}
            updatingOrder={updatingOrder}
            saveOrderClicked={handleSaveOrderClicked}
            headphonesInteractive={headphonesInteractive}
            toggleCover={toggleCover}
            createNewHeadphone={createNewHeadphone}
            createNewCover={createNewCover}
          />
          <div className={`flex flex-col-reverse min-h-0 md:pt-0 md:relative md:flex-row md:flex-1 items-center`}>
            <DynamicHeadphones
              orderItem={selectedOrderItem}
              viewName={viewName}
              className={`transition-all duration-500 max-w-[100%] md:max-w-auto ease-kibu
                ${component === 'colour'
                  ? (headphonesInteractive ? 'scale-100 delay-0' : 'scale-[97%] delay-0')
                  : 'scale-[94%] delay-0'}
              `}
              custom_colours={order?.items.find(item => item.item_uid.$oid === activeOrderItemId)?.addons?.find(addon => addon.product_id.$oid === addonIds.custom_colours)?.custom_colours}
              colourway_name={order?.items.find(item => item.item_uid.$oid === activeOrderItemId)?.colours}
              toggleColourPicker={toggleColourPicker}
              interactive={headphonesInteractive}
              engraving={engravingText}
              cover={showCover}
              coverColour={coverColour}
              toggleCover={toggleCover}
            />
            {renderView()}
          </div>
          <div className='help help fixed bottom-2 z-20 text-xs md:text-base md:bottom-4 w-full flex justify-center gap-4 md:gap-8'>
            <div
              className='flex justify-center items-center gap-1 cursor-pointer opacity-30 hover:opacity-100'
              onClick={helpEmailWithOrderNumber}>
              <BadgeHelp className='h-4 w-4' /> Need help?
            </div>
          </div>
          <div className={`shipping-modal fixed z-30 flex p-4 items-center justify-center w-screen h-screen bg-black/60 transition-all ease-kibu duration-500 transform-gpu ${shippingModalOpen ? 'opacity-100 backdrop-blur-lg delay-0' : 'opacity-0 backdrop-blur-none delay-200 pointer-events-none'}`}>
            <ShippingForm onCancel={() => setShippingModalOpen(false)} shippingModalOpen={shippingModalOpen} updateOrderOnServer={updateOrderOnServer} />
          </div>
          <div className={`welcome absolute flex flex-col gap-4 items-center left-0 top-0 p-4 md:pt-20 z-40 w-screen h-[100svh] bg-black/60 backdrop-blur-md transition-all ease-kibu duration-500 delay-75 ${welcomeModalClosed ? 'opacity-0 backdrop-blur-none pointer-events-none' : 'opacity-100 backdrop-blur-lg'} overflow-y-auto`}>
            <span className='text-center justify-center w-full text-sm text-white flex items-center gap-1'><Monitor className='h-4 w-4' /> Best experienced on desktop.</span>
            <div className={`bg-offwhite rounded-5xl shadow-modal text-center p-6 md:p-8 text-lg max-w-xl flex flex-col justify-center items-center gap-5 md:gap-10
              transition-all ease-kibu duration-200 ${welcomeModalClosed ? 'scale-75 opacity-0 blur-md' : 'scale-100 opacity-100 blur-none'}`}>
              <div className='text-3xl flex justify-center items-center mt-0 md:mt-5'>
                <div className='h-12 md:h-16 aspect-square bg-contain bg-no-repeat bg-center bg-logo-orange'></div>
              </div>
              <div className='flex flex-col gap-4 md:gap-8 leading-snug items-center text-base md:text-lg'>
                Welcome to the Kibu Headphone Configurator.
                Please configure all of your items to finish your order and we can get it ready for you and ship it over.
                <div className='flex flex-col items-center gap-4 p-6 text-sm bg-grey/10 border border-grey/30 rounded-md max-w-96'>
                  Each headphone has features you can toggle and customize. There's 3 different states for addons:
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 bg-orange rounded-full'></div>
                    Paid for and configured
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 border border-2 border-orange rounded-full'></div>
                    Needs configuration
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 border border-grey border-2 border-dotted rounded-full'></div>
                    Inactive. Can be purchased.
                  </div>
                </div>
              </div>
              <button className='bg-orange p-4 px-8 rounded-full w-full text-white text-lg' onClick={() => setWelcomeModalClosed(true)}>
                I'm ready, let's go!
              </button>
            </div>
          </div>
        </div>
      );
    } else if (selectedOrderItem?.product_id.$oid === "6637dd0c02a2b22450ef3188") {
      return (
        <div className={`view main relative overflow-hidden w-screen bg-background h-[100dvh] max-h-full flex flex-col gap-4 md:gap-0 transition-bg ease-kibu duration-200 ${headphonesInteractive ? 'bg-orange/10' : 'bg-background'}`}>
          <Nav headphonesInteractive={headphonesInteractive} order={order} finishOrderClicked={openShippingModal} saveOrderClicked={handleSaveOrderClicked} updatingOrder={updatingOrder} toggleCover={toggleCover} createNewHeadphone={createNewHeadphone} createNewCover={createNewCover} />
          <div className={`flex flex-col-reverse min-h-0 md:pt-0 md:relative md:flex-row md:flex-1 items-center`}>
            <DynamicCover
              orderItem={selectedOrderItem}
              viewName="front_view"
              className={`transition-all duration-500 max-w-[100%] md:max-w-auto ease-kibu scale-[94%] delay-0 h-full md:mb-0`}
              type={"central"}
            />
            {renderView(true)}
          </div>
          <div className='help help fixed bottom-2 z-20 text-xs md:text-base md:bottom-4 w-full flex justify-center gap-4 md:gap-8'>
            <div
              className='flex justify-center items-center gap-1 cursor-pointer opacity-30 hover:opacity-100'
              onClick={helpEmailWithOrderNumber}>
              <BadgeHelp className='h-4 w-4' /> Need help2?
            </div>
          </div>
          <div className={`shipping-modal fixed z-30 flex p-4 items-center justify-center w-screen h-screen bg-black/60 transition-all ease-kibu duration-500 transform-gpu ${shippingModalOpen ? 'opacity-100 backdrop-blur-lg delay-0' : 'opacity-0 backdrop-blur-none delay-200 pointer-events-none'}`}>
            <ShippingForm onCancel={() => setShippingModalOpen(false)} shippingModalOpen={shippingModalOpen} updateOrderOnServer={updateOrderOnServer} />
          </div>
          <div className={`welcome absolute flex flex-col gap-4 items-center left-0 top-0 p-4 md:pt-20 z-40 w-screen h-[100svh] bg-black/60 backdrop-blur-md transition-all ease-kibu duration-500 delay-75 ${welcomeModalClosed ? 'opacity-0 backdrop-blur-none pointer-events-none' : 'opacity-100 backdrop-blur-lg'} overflow-y-auto`}>
            <span className='text-center justify-center w-full text-sm text-white flex items-center gap-1'><Monitor className='h-4 w-4' /> Best experienced on desktop.</span>
            <div className={`bg-offwhite rounded-5xl shadow-modal text-center p-6 md:p-8 text-lg max-w-xl flex flex-col justify-center items-center gap-5 md:gap-10
              transition-all ease-kibu duration-200 ${welcomeModalClosed ? 'scale-75 opacity-0 blur-md' : 'scale-100 opacity-100 blur-none'}`}>
              <div className='text-3xl flex justify-center items-center mt-0 md:mt-5'>
                <div className='h-12 md:h-16 aspect-square bg-contain bg-no-repeat bg-center bg-logo-orange'></div>
              </div>
              <div className='flex flex-col gap-4 md:gap-8 leading-snug items-center text-base md:text-lg'>
                Welcome to the Kibu Headphone Configurator.
                Please configure all of your items to finish your order and we can get it ready for you and ship it over.
                <div className='flex flex-col items-center gap-4 p-6 text-sm bg-grey/10 border border-grey/30 rounded-md max-w-96'>
                  Each headphone has features you can toggle and customize. There's 3 different states for addons:
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 bg-orange rounded-full'></div>
                    Paid for and configured
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 border border-2 border-orange rounded-full'></div>
                    Needs configuration
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='w-7 h-7 border border-grey border-2 border-dotted rounded-full'></div>
                    Inactive. Can be purchased.
                  </div>
                </div>
              </div>
              <button className='bg-orange p-4 px-8 rounded-full w-full text-white text-lg' onClick={() => setWelcomeModalClosed(true)}>
                I'm ready, let's go!
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      // Your default component rendering
      return (
        <div className="flex-1 flex items-center justify-center">
          <p>Unknown product type.</p>
        </div>
      );
    }
  };

  return renderComponentBasedOnProductId();

}

export default Main